<template>
<v-container>
    <v-layout row justify-center class="pasarela">
            <template v-if="!consultado">
                <v-flex xs12 class="text-center">
                    <v-img :src="require('../../assets/rpa.png')" contain height="150"></v-img>
                </v-flex>
                <v-flex xs12 class="text-center">
                    <v-img src="https://adm-ppe.demo.agetic.gob.bo/adm/img/logo-ppe-dark.svg" contain height="70"></v-img>
                </v-flex>
                <v-flex xs12 class="text-center" >
                    <v-progress-circular :size="50" color="orange" indeterminate></v-progress-circular>
                    <span class="ml-5"><b>Verificando datos del pago,</b> porfavor espere un momento ...</span>
                </v-flex>
            </template>
            <v-flex xl8 lg8 md9 sm10 xs12 class="text-left" v-else>
                <v-card class="my-0 py-0">
                    <v-card-text class="py-10">
                        <v-layout row wrap align-center>
                            <v-flex xs12 lg6>
                                <v-img src="https://adm-ppe.demo.agetic.gob.bo/adm/img/logo-ppe-dark.svg" contain height="80"></v-img>    
                            </v-flex>
                            <v-flex xs12 lg6>
                                <div class="text-h5 text-center font-weight-black mb-5">
                                    <div v-if="query.estado && query.estado === 'PROCESADO'">
                                        <v-icon color="success" x-large>mdi-check-decagram</v-icon>
                                        Pago realizado correctamente
                                    </div>
                                    <div v-else>
                                        <v-icon color="warning" x-large>mdi-alert-decagram</v-icon>
                                        Pago pendiente de verificación
                                    </div>
                                </div>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title><b>Código de transacción: </b>{{query.codigoTransaccion}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.fechaRespuesta">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Fecha solicitud: </b> {{query.fechaRespuesta}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.estado">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Estado: </b>
                                            <v-chip :color="getColor(query.estado)">{{query.estado}}</v-chip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.tipoTramite">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Trámite: </b> {{query.tipoTramite}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.cuentaBancaria">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Cuenta bancaria: </b> {{query.cuentaBancaria}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.metodoPago">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Método pago: </b> {{query.metodoPago}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.montoTotal">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Monto total: </b>{{query.montoTotal}} Bs.</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.fechaNotificacion">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Fecha pago: </b> {{query.fechaNotificacion}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="query.nombreCliente">
                                    <v-list-item-content>
                                        <v-list-item-title><b>Nombres: </b> {{query.nombreCliente}} {{query.apellidosCliente}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> 
                            </v-flex>
                        </v-layout>
                        <!-- <div class="my-5">
                            <v-img src="https://adm-ppe.demo.agetic.gob.bo/adm/img/logo-ppe-dark.svg" contain height="70"></v-img>
                        </div>
                        <div class="text-h5 text-center font-weight-black">Solicitud de la pasarela de pagos</div>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>Código de transaccion: </b>{{query.codigoTransaccion}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.fechaRespuesta">
                            <v-list-item-content>
                                <v-list-item-title><b>Fecha solicitud: </b> {{query.fechaRespuesta}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.estado">
                            <v-list-item-content>
                                <v-list-item-title><b>Estado: </b>
                                    <v-chip :color="getColor(query.estado)">{{query.estado}}</v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.tipoTramite">
                            <v-list-item-content>
                                <v-list-item-title><b>Tramite: </b> {{query.tipoTramite}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.cuentaBancaria">
                            <v-list-item-content>
                                <v-list-item-title><b>Cuenta bancaria: </b> {{query.cuentaBancaria}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.metodoPago">
                            <v-list-item-content>
                                <v-list-item-title><b>Metodo pago: </b> {{query.metodoPago}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.montoTotal">
                            <v-list-item-content>
                                <v-list-item-title><b>Monto total: </b>{{query.montoTotal}} Bs.</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.fechaNotificacion">
                            <v-list-item-content>
                                <v-list-item-title><b>Fecha pago: </b> {{query.fechaNotificacion}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="query.nombreCliente">
                            <v-list-item-content>
                                <v-list-item-title><b>Nombres: </b> {{query.nombreCliente}} {{query.apellidosCliente}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-card-text>
                    <v-card-actions class="pb-10">
                        <v-spacer></v-spacer>
                        <!-- <v-btn rounded color="default" text to="/rpa"><v-icon> mdi-home </v-icon> Volver al inicio</v-btn> -->
                        <v-btn rounded color="primary" @click="getDatosRegistro()"><v-icon> mdi-arrow-right-thick </v-icon> continuar</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
</v-container>        
</template>
<script>
import mixinRegistro from '@/mixins/registro'
export default {
    mixins:[mixinRegistro],
    data(){
        return{
            query:this.$route.query,
            consultado:false,
            codigoTansaccion:null
        }
    },
    created(){
        console.log('------------------------------------');
        console.log(this.$route.fullPath);
        console.log('------------------------------------');
        if(this.query.codSeguimiento){
            this.codigoTansaccion = this.query.codSeguimiento
            if(!this.esEntero(this.query.codSeguimiento) && this.esBase64(this.query.codSeguimiento)){
                this.codigoTansaccion = atob(this.query.codSeguimiento)
            }
            this.vertificarSolicitudPasarela()
        }else{
            const codigo =this.$storage.get('codigo_transaccion')
            if(codigo){
                this.codigoTansaccion = codigo
                this.vertificarSolicitudPasarela()
                this.$storage.remove('codigo_transaccion')
            }else{
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error Ruta",
                    text: "El parametro codSeguimiento es requerido"
                })
                setTimeout(()=>{
                    this.getDatosRegistro()
                },2000)
            }
        }
    },
    methods:{
        getColor(estado){
            const colores = {
                'CREADO':'grey',
                'SOLICITADO':'orange',
                'EN_PROCESO':'orange',
                'PROCESADO':'success',
            }
            return colores[estado]||'error'
        },
        vertificarSolicitudPasarela(){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago?codigoTransaccion=${this.codigoTansaccion}`)
            .then(response=>{
                const {data } = response.data
                if(data){
                    this.query = {
                        ...this.query,
                        ...data
                    }
                    if(this.query.rutaCliente){
                        this.$storage.set("continue", this.query.rutaCliente);
                    }
                }
                this.consultado=true
            }).catch(error=>{
                this.consultado=true
                setTimeout(()=>{
                    this.getDatosRegistro()
                },3000)
                console.log('-----------------vertificarSolicitudPasarela-------------------');
                console.log(error);
                console.log('------------------------------------');
            })
        },
        esBase64(valor){
            const expresionRegularBase64 = /^[A-Za-z0-9+/]+[=]{0,2}$/;
            if (valor.length % 4 !== 0) {
                return false;
            }
            if (!expresionRegularBase64.test(valor)) {
                return false;
            }
            try {
                atob(valor);
                return true;
            } catch (error) {
                return false;
            }
        },
        esEntero(cadena) {
            const expresionRegularEntero = /^-?\d+$/;
            return expresionRegularEntero.test(cadena);
        }       
    }
}
</script>
<style>
.pasarela{
    padding-top: 5vh;
}
</style>